import config from '@/Constants';
import { useFilter } from '@/hooks/Filters/filter';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { useTasks } from '@/screens/registrations/Tasks/Dashboard/hooks/tasks';
import { Grid, GridItem, Skeleton } from '@chakra-ui/react';
import React from 'react';
import useSWR from 'swr';

import StatsItem from './Stats.Item';
import { StatData, StatType, statTypes } from './Stats.types';

const Stats: React.FC = () => {
  const { responsibleFilter } = useTasks();
  const { getQueryString } = useFilter();

  const filtersQuery = React.useMemo(() => {
    return getQueryString?.();
  }, [getQueryString]);

  const { data } = useSWR<Record<StatType, StatData>>(
    `${config.TASKS_URL}/count_status?${
      responsibleFilter === 'my_tasks' ? '&only_my_tasks=true' : ''
    }${filtersQuery}`,
    async (url) => {
      const response = await AxiosHelper.get({ url });
      return response.body;
    },
    { revalidateOnFocus: false },
  );

  const renderItems = React.useCallback(() => {
    if (!data) {
      return (
        <>
          <Skeleton h="28" w="full" borderRadius="12px" />
          <Skeleton h="28" w="full" borderRadius="12px" />
          <Skeleton h="28" w="full" borderRadius="12px" />
          <Skeleton h="28" w="full" borderRadius="12px" />
          <Skeleton h="28" w="full" borderRadius="12px" />
          <Skeleton h="28" w="full" borderRadius="12px" />
          <Skeleton h="28" w="full" borderRadius="12px" />
          <Skeleton h="28" w="full" borderRadius="12px" />
        </>
      );
    }

    const entries = Object.entries(data) as [StatType, StatData][];
    return entries.map((entry) => {
      const [type, statData] = entry;
      return (
        <GridItem
          key={type}
          border="1px solid #E2E8F0"
          bg="white"
          borderRadius="12px"
          px={4}
          py={6}
        >
          <StatsItem type={type} data={statData} />
        </GridItem>
      );
    });
  }, [data]);

  return (
    <Grid
      templateColumns={`repeat(${statTypes.length}, 1fr)`}
      gap={4}
      mb={4}
      w="100%"
    >
      {renderItems()}
    </Grid>
  );
};

export default Stats;
