import ExporterContextMenu from '@/components_v2/ExporterContextMenu';
import { useActionCable } from '@/hooks/actionCable';
import { BaseExportData } from '@/hooks/RemoteFilesListener/interfaces';
import StatusTag from '@/screens/monitoring/Checklists/components/Board/components/StatusTag';
import { invariantViolation } from '@/utils/Exceptions/invariantViolation';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { BiDownload, BiImage } from 'react-icons/bi';

import { getExporter } from '../../data/getExporter';
import { useEventSummary } from '../../hooks/useEventSummary';
import { useEventValidationProps } from '../../hooks/useEventValidationProps';

function useExportListener() {
  const { subscribe } = useActionCable();
  const unsubRef = React.useRef<() => void>();

  const subscribeRef = React.useRef<(payload: BaseExportData) => void>(
    (data) => {
      if (data?.status === 'failure' || data.status === 'done') {
        window.open(data.downloadFileUrl);
        unsubRef.current?.();
      }
      //
    },
  );

  const createConsumer = React.useCallback(
    (exporter: BaseExportData & { token: string }) => {
      const { token, downloadFileUrl, status } = exporter;
      if (status === 'done' && downloadFileUrl) {
        window.open(downloadFileUrl);
      } else if (status !== 'failure') {
        unsubRef.current = subscribe?.('export', subscribeRef.current, token);
      }
    },
    [subscribe],
  );

  return { createConsumer };
}

export function usePictureZipExporter() {
  const { createConsumer } = useExportListener();

  const toast = useToast();
  const instance = React.useRef(getExporter('zip')('events')).current;

  const [isLoading, setIsLoading] = React.useState(false);

  const exportZip = React.useCallback(
    async (objectId: string | string[]) => {
      setIsLoading(true);

      try {
        const response = await instance.execute({
          parentable_type: 'events',
          options: Array.isArray(objectId)
            ? { arrayObjectIds: objectId }
            : { objectId },
        });

        invariantViolation(
          response.statusCode >= 200 && response.statusCode < 300,
          `api response: ${response.statusCode}`,
        );
        createConsumer(response.body);
        toast({
          title: 'Seu download iniciará em breve...',
          duration: 3000,
          status: 'info',
        });
      } catch (e) {
        toast({
          title: 'Ocorreu um erro.',
          description: 'Tente novamente',
          duration: 3000,
          status: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [createConsumer, instance, toast],
  );
  return {
    exportZip,
    isLoading,
  };
}

const InspectionStat: React.FC<{ label: string }> = ({ children, label }) => {
  return (
    <Flex gap="1.5" alignItems="center" flexDir="row">
      <Box h="5" w="0.5" bg="gray.400" />
      <Text fontWeight="bold" color="mutedText">
        {label}
      </Text>
      <Box color="darkText">{children}</Box>
    </Flex>
  );
};

export const Header: React.FC<{
  standaloneMode?: boolean;
  onClose?: () => void;
}> = ({ standaloneMode, onClose }) => {
  const { createConsumer } = useExportListener();
  const { event, onUpdate, isMutating } = useEventSummary();
  const { validationProps } = useEventValidationProps(event);

  const parsedFinishedAt = React.useMemo(
    () => (event?.finishedAt ? new Date(event?.finishedAt) : null),
    [event?.finishedAt],
  );

  const handleValidate = React.useCallback(async () => {
    if (!onUpdate || !event) return;
    await onUpdate({ id: event.id, validated: !event?.validated });
  }, [event, onUpdate]);

  const onExporterSuccess = React.useCallback(
    (data: BaseExportData & { token: string }) => {
      createConsumer(data);
    },
    [createConsumer],
  );

  const { exportZip, isLoading } = usePictureZipExporter();

  return (
    <Box
      w="full"
      minH={{ base: '32', lg: '24' }}
      bg="white"
      borderBottomWidth="thin"
      alignItems="center"
      justifyContent="flex-start"
      display="flex"
      boxShadow="sm"
      gap="4"
    >
      <Box
        borderRightWidth="thin"
        h="full"
        alignItems="center"
        display="flex"
        onClick={onClose}
        hidden={standaloneMode}
      >
        <IconButton
          icon={<AiOutlineLeft />}
          aria-label="go-back"
          fontSize="24px"
          variant="ghost"
          rounded="none"
          h="full"
          w={{ base: '12', lg: '24' }}
        />
      </Box>
      <Flex
        alignItems={{ base: 'flex-start', lg: 'center' }}
        p="4"
        flex="1"
        h="full"
        flexDir={{ base: 'column', lg: 'row' }}
        justifyContent="space-between"
      >
        <VStack alignItems="flex-start" spacing="2">
          <HStack alignItems="center" ms={{ base: '-2', lg: '0' }}>
            <Avatar
              display={{ base: 'none', lg: 'inherit' }}
              borderWidth="3px"
              borderColor="white"
              boxShadow="lg"
              mt="-1.5"
              name={event?.authorName}
              translate="no"
            />
            <Box w="full">
              <Heading
                wordBreak="break-word"
                display="inline-block"
                verticalAlign="middle"
                lineHeight="0.9"
                me="2"
                size="lg"
                translate="no"
              >
                {`${event?.parentableName}`}
              </Heading>

              <Box my="1" display="inline-block">
                <StatusTag status={event?.status || 'pending'} />
              </Box>
            </Box>
          </HStack>
          <Flex
            h="min-content"
            justifyContent="center"
            flexDirection={{ base: 'column', lg: 'row' }}
            gap={{ base: '4', lg: '2' }}
          >
            <Text fontWeight="bold" color="mutedText" translate="no">
              {`${event?.authorName} ${
                event?.userProfileName ? `/ ${event?.userProfileName}` : ''
              } / ${
                parsedFinishedAt ? moment(parsedFinishedAt).format('LLLL') : ''
              }`}
            </Text>

            {!!event?.finishedAt && (
              <InspectionStat label="Itens Respondidos:">{`${event?.collectedItemsCount}`}</InspectionStat>
            )}
            {!!event?.finishedAt && (
              <InspectionStat label="Duração:">
                {event?.duration}
              </InspectionStat>
            )}
          </Flex>
        </VStack>

        <ButtonGroup
          mt={{ base: '4', lg: '0' }}
          size="md"
          w={{ base: '91.5%', lg: 'fit-content' }}
        >
          <Button
            flex={{ base: 1, lg: 'unset' }}
            onClick={() => exportZip(event!.id)}
            isLoading={isLoading}
            colorScheme="primary"
            leftIcon={<BiImage fontSize="22px" />}
            translate="no"
          >
            Baixar Fotos
          </Button>
          <ExporterContextMenu
            getExporter={getExporter()}
            hasPortal={false}
            onSuccess={onExporterSuccess}
            options={{ objectId: event?.id || '' }}
            button={
              <Button
                color="mutedText"
                flex={{ base: 1, lg: 'unset' }}
                leftIcon={<BiDownload fontSize="22px" />}
                translate="no"
              >
                PDF
              </Button>
            }
            screenName="events"
          />

          <Button
            flex={{ base: 1, lg: 'unset' }}
            isLoading={isMutating}
            onClick={handleValidate}
            colorScheme={validationProps?.colorScheme}
            rightIcon={validationProps?.icon}
            translate="no"
          >
            {validationProps?.label}
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
